.play-heading.alter {
  padding-inline: 50px !important;
}
.buy-desc {
  /* max-width: 1100px; */
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #000000;
  text-align: center;
  margin: 0 auto;
}
.buy-btn {
  margin-top: 45px;
  text-align: center;
  justify-content: center;
}
.icons-buy {
  margin-top: 60px;
  margin-bottom: 20px;
  padding: 50px 0;
  max-width: 840px;
  box-shadow: 0px 8px 31px rgba(171, 171, 171, 0.2);
  border-radius: 30px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 80px;
  row-gap: 55px;
  flex-wrap: wrap;
}
.indiv-buy {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto; 
}

.indiv-buy img {
  width: 100%; 
  height: auto;
  max-width: 1020px; 

}

.indiv-buy p {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;

  color: #000000;
}
@media (max-width: 768px) {
  .indiv-buy {
    width: 100%;
  }
}
@media (max-width: 400px) {
  .play-heading.alter {
    padding-inline: 25px !important;
  }
}
